ion-router-outlet {
    .ion-page {
        bottom: var(
            --offset-top
        ); // Seulement sur les pages affichées dans le routeur pour contrecarrer l'offset du header de l'app.

        &:not(app-home) {
            ion-header:not(.login-header) {
                background-color: var(--ion-color-medium-light);
                padding-top: calc(var(--offset-top) - var(--ion-margin));

                ion-toolbar {
                    padding-bottom: 0;
                }

                ion-title {
                    margin: var(--space-m);
                }
            }
        }
    }
}

.split-pane-visible {
    // Faisons remonter le header des contenus en mode
    // desktop (back button) au même niveau que le
    // bouton Allo360.

    box-shadow: 0 0 var(--space-m) rgba(black, 0.2);

    ion-router-outlet {
        .ion-page {
            &:not(app-home) {
                ion-header:not(.login-header) {
                    padding-top: 0;
                }
            }
        }
    }

    #main-content {
        ion-header {
            pointer-events: none;

            ion-button {
                pointer-events: auto;
            }
        }
    }
}

ion-app {
    background-color: var(--ion-color-light-blue);
}

ion-split-pane {
    max-width: 102.4rem;
    margin: 0 auto;
    --side-min-width: 37rem;
    --side-width: 37rem;
}

ion-menu {
    --min-width: 100%;
    --width: 100%;
}

ion-content[emoticCustomScrollbar] {
    --padding-start: var(--ion-margin);
    --padding-end: var(--ion-margin);
    --padding-top: var(--ion-margin);
    --padding-bottom: var(--ion-margin);
}

ion-header {
    ion-toolbar:not(.login-header) {
        &:last-of-type {
            padding-bottom: var(--ion-safe-area-bottom, 0);
        }
    }

    &.header-md::after {
        left: 0;
        bottom: initial;
        background: var(--ion-color-medium);
        height: 1px;
    }
}

ion-footer {
    text-align: center;

    ion-toolbar,
    ion-toolbar:last-of-type {
        --background: var(--ion-color-medium-light);
        padding-top: calc(var(--ion-safe-area-bottom, --ion-padding) * 0.5);
        padding-bottom: calc(var(--ion-safe-area-bottom, --ion-padding) * 0.5);
    }
}

ion-toolbar {
    --min-height: initial;
    --padding-start: var(--ion-margin);
    --padding-end: var(--ion-margin);

    h1 {
        margin: 0;
    }

    .header-suptitle {
        display: block;
        margin-bottom: var(--ion-padding);
    }
}

ion-buttons {
    &.buttons-first-slot,
    &.buttons-last-slot {
        margin-inline-start: 0;
        margin-inline-end: 0;
    }
}

*,
.sc-ion-buttons-md-s {
    ion-button {
        --border-width: 1px;
        --padding-start: var(--space-s);
        --padding-end: var(--space-s);
        --box-shadow: none;
        height: var(--space-xl);
        font-size: var(--font-size-s);
        font-weight: 700;
        margin: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;

        &:not(.button-round) {
            --border-radius: 0.4rem;
            border-radius: 0.4rem;
        }

        &.square {
            width: var(--space-xl);
        }

        &[fill='outline'] {
            --color: var(--ion-color-primary) !important;
            --background: var(--ion-color-primary-contrast);
            // --border-width à 0 pour masquer la bordure du composant ionic,
            // car elle prend la couleur --color du bouton.
            // Impossible de lui assigner une couleur différente proprement.
            --border-width: 0;
            // Et ajout d'une bordure sur le bouton avec la bonne couleur :
            border: 1px solid var(--ion-color-light-blue);
            overflow: overlay;
        }
    }
}

ion-title {
    --font-weight: 800;
    font-weight: 800;
    font-size: var(--font-size-xl);
    padding-inline: 0;
}

ion-note {
    display: inline-block;
    font-size: 16px;

    color: var(--ion-color-medium-shade);
}

ion-item {
    --highlight-height: 0;
    --color: var(--ion-color-primary);
}

ion-card {
    background-color: var(--ion-color-secondary);
    box-shadow: none;
    border: 1px solid var(--ion-color-light-blue);
    margin: 0;

    &.highlight {
        border-color: var(--ion-color-primary);
    }

    &.important {
        border-color: var(--ion-color-danger);
    }

    &.bgimportant {
        --ribbon-size: 1.6rem;

        &:before {
            content: '';
            position: absolute;
            right: 0px;
            top: 0px;
            width: 0;
            height: 0;
            border: var(--ribbon-size) solid var(--ion-color-danger);
            border-bottom-color: transparent;
            border-left-color: transparent;
        }
        &:after {
            font-family: 'Phosphor';
            content: '\fa5d'; // '\f3e9'; // '\f06b'; // Star : '\f2f3';
            position: absolute;
            font-size: calc(var(--ribbon-size) * 0.6);
            line-height: 1;
            text-align: center;
            right: 0.16rem;
            top: 0.6rem;
            width: var(--ribbon-size);
            height: var(--ribbon-size);
            color: white;
        }
        // background-color: var(--ion-color-light-blue);
    }

    &.card-list-wrapper {
        margin-bottom: var(--space-m);

        &.highlight {
            border-color: var(--ion-color-primary);
        }

        ion-card-content {
            font-size: var(--font-size-m);
            font-weight: 400;
        }
    }
}

ion-input,
ion-textarea {
    *,
    &.sc-ion-input-md-h,
    &.sc-ion-textarea-md-h,
    &.item-label-stacked,
    &.item-label-stacked .sc-ion-input-md-h,
    .sc-ion-textarea-md-h {
        font-size: var(--font-size-m);
        line-height: normal;
        --background: white;
        --padding-start: var(--space-xxs);
        --padding-end: var(--space-xxs);
        --placeholder-color: var(--default-placeholder-color);
        --placeholder-opacity: var(--default-placeholder-opacity);
        border-style: solid;
        border-radius: var(--default-border-radius);
        border-color: var(--default-border-color);
        border-width: var(--default-border-width);
        color: var(--ion-color-dark);
        margin-top: 0;
    }

    .native-input[disabled].sc-ion-input-md {
        opacity: var(--input-disabled-opacity);
    }
}

ion-select {
    font-size: var(--font-size-m);
    line-height: normal;
    --background: white;
    --padding-start: var(--space-xxs);
    --padding-end: var(--space-xxs);
    --placeholder-color: var(--default-placeholder-color);
    --placeholder-opacity: var(--default-placeholder-opacity);
    background-color: white;
    border-style: solid;
    border-radius: var(--default-border-radius);
    border-color: var(--default-border-color);
    border-width: var(--default-border-width);
    color: var(--ion-color-dark);

    &::part(icon) {
        transform: translate3d(0, 0, 0);
    }
}

ion-item {
    &.item-has-focus {
        ion-select {
            &::part(icon) {
                transform: translate3d(0, 0, 0) rotate(180deg);
            }
        }
    }
}

.item-interactive-disabled.sc-ion-label-md-h:not(.item-multiple-inputs),
.item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-md-h {
    opacity: var(--input-disabled-opacity);
}

ion-segment {
    --background: white;
    border: 1px solid var(--default-border-color);
    border-radius: var(--default-border-radius);
    --min-height: 4.3rem;
    min-height: var(--min-height);
    height: var(--min-height);

    ion-segment-button {
        --color: var(--ion-color-dark);
        --color-checked: var(--ion-color-secondary);
        --background: none;
        --background-checked: var(--ion-color-primary);
        --background-hover: var(--background-checked);
        --background-focused: var(--background-checked);
        --background-activated-opacity: 1;
        --background-focused-opacity: 0.12;
        --background-hover-opacity: 0.04;
        --indicator-box-shadow: none;
        --indicator-color: var(--color-checked);
        --indicator-height: 0;
        --indicator-transition: none;
        --indicator-transform: none;

        text-transform: initial;
        font-weight: 600;
        font-size: var(--font-size-m);
        min-height: var(--min-height);
        height: var(--min-height);

        margin-top: -1px;

        ion-label {
            margin: 0;
        }
    }
}

ion-datetime {
    --padding-start: 0.8rem;
    --padding-end: 0.8rem;
    --padding-top: 0.8rem;
    --padding-bottom: 0.8rem;
    --background: white;
    --placeholder-color: var(--default-placeholder-color);
    --placeholder-opacity: var(--default-placeholder-opacity);
    background-color: white;
    border: 1px solid var(--default-border-color);
    border-radius: var(--default-border-radius);
    color: var(--ion-color-dark);
    height: var(--default-input-height);

    &::part(placeholder) {
        color: var(--placeholder-color);
        opacity: var(--placeholder-opacity);
    }
}

ion-searchbar {
    &.sc-ion-searchbar-md-h {
        --box-shadow: none;
        --background: var(--ion-color-secondary);
        --icon-color: transparent; // var(--ion-color-primary);
        --border-radius: var(--default-border-radius);
        border: var(--default-border-width) solid var(--default-border-color);
        padding: 0;
    }

    &:after {
        font-family: 'Phosphor' !important;
        content: '\f5e6';
        position: absolute;
        left: var(--space-s);
        color: var(--ion-color-primary);
        font-size: var(--font-size-l);
    }
}

ion-list {
    &.list-contacts-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        ion-card {
            flex: 0 48%;
            padding: var(--space-m);
            margin: var(--space-xxs) 0 var(--space-xxs) 0;

            .contact-wrapper {
                margin: var(--space-m);

                ion-card-title {
                    font-size: var(--font-size-m);
                    font-weight: 600;
                    margin-bottom: var(--space-xxxs);
                }

                ion-card-subtitle {
                    font-size: var(--font-size-s);
                    font-weight: 400;
                    color: var(--ion-color-dark);
                }
            }
        }
    }
}

ion-popover,
.sc-ion-popover-md-h {
    &:not(.select-popover) {
        --width: 30rem;
        --min-width: 30rem;
        --min-height: 23rem;
        --box-shadow: none;

        div.popover-content {
            transform-origin: center center !important;
        }
    }

    &.select-popover {
        --width: initial;
        --max-width: calc(100% - var(--ion-margin) * 2);
    }
}

ion-toast {
    --border-radius: var(--default-border-radius);
    --box-shadow: none;
}

ion-checkbox {
    --background: var(--ion-color-secondary);
    --border-color: var(--ion-color-light-blue);
    --border-radius: var(--default-border-radius);
    --border-width: var(--default-border-width);
    // --size: var(--space-m);
    // --background-checked	Background of the checkbox icon when checked
    // --border-color-checked	Border color of the checkbox icon when checked
    // --border-style	Border style of the checkbox icon
    // --checkmark-color	Color of the checkbox checkmark when checked
    // --checkmark-width	Stroke width of the checkbox checkmark
}

ion-radio {
    background-color: white;
    border-radius: 50%;
    margin-top: var(--space-xxs);
    margin-bottom: var(--space-xxs);
    margin-left: unset;
    margin-right: unset;
    margin-inline-start: 0;
    margin-inline-end: var(--ion-padding);
    --color: var(--ion-color-light-blue);
    --border-width: var(--default-border-width);

    &.sc-ion-select-popover {
        opacity: 0;
    }
}

ion-virtual-scroll {
    .virtual-item {
        min-height: 8rem;
    }
}
