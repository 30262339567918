loader {
    .loader {
        top: 0 !important;
        --message-margin-top: var(--space-m);
        --spinner-color: var(--ion-color-primary);
        --message-color: var(--ion-color-primary);

        .loader-content {
            .message {
                font-weight: 600;
                width: 24rem;
            }
        }
    }
}
