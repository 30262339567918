// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    ////// COLORS

    --ion-color-primary: #03409b;
    --ion-color-primary-rgb: 3, 64, 155;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #033888;
    --ion-color-primary-tint: #1c53a5;

    --ion-color-secondary: #ffffff;
    --ion-color-secondary-rgb: 255, 255, 255;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #e0e0e0;
    --ion-color-secondary-tint: #ffffff;

    --ion-color-tertiary: #002f7b;
    --ion-color-tertiary-rgb: 0, 47, 123;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #00296c;
    --ion-color-tertiary-tint: #1a4488;

    --ion-color-success: #00872e;
    --ion-color-success-rgb: 0, 135, 46;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #007728;
    --ion-color-success-tint: #1a9343;

    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    --ion-color-danger: #a41116;
    --ion-color-danger-rgb: 164, 17, 22;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #900f13;
    --ion-color-danger-tint: #ad292d;

    --ion-color-dark: #262525;
    --ion-color-dark-rgb: 38, 37, 37;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #212121;
    --ion-color-dark-tint: #3c3b3b;

    --ion-color-medium: #a6a6ac;
    --ion-color-medium-rgb: 166, 166, 172;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #929297;
    --ion-color-medium-tint: #afafb4;

    --ion-color-light: #ffffff;
    --ion-color-light-rgb: 255, 255, 255;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #e0e0e0;
    --ion-color-light-tint: #ffffff;

    --ion-color-medium-light: #f4f4f4;
    --ion-color-medium-light-rgb: 244, 244, 244;
    --ion-color-medium-light-contrast: #000000;
    --ion-color-medium-light-contrast-rgb: 0, 0, 0;
    --ion-color-medium-light-shade: #d7d7d7;
    --ion-color-medium-light-tint: #f5f5f5;

    --ion-color-light-blue: #e5eaf2;
    --ion-color-light-blue-rgb: 229, 234, 242;
    --ion-color-light-blue-contrast: #000000;
    --ion-color-light-blue-contrast-rgb: 0, 0, 0;
    --ion-color-light-blue-shade: #caced5;
    --ion-color-light-blue-tint: #e8ecf3;

    --ion-background-color: var(--ion-color-medium-light);
    --ion-background-color-rgb: var(--ion-color-medium-light-rgb);

    --ion-text-color: var(--ion-color-dark);
    --ion-text-color-rgb: var(--ion-color-dark-rgb);

    --ion-toolbar-background: transparent;

    ////// FONTS

    --ion-font-family: 'Raleway', sans-serif;

    --font-size-xs: 1rem;
    --font-size-s: 1.2rem;
    --font-size-m: 1.6rem;
    --font-size-l: 2rem;
    --font-size-xl: 2.4rem;
    --font-size-xxl: 2.8rem;

    --default-font-size: var(--font-size-m);

    ////// SPACES

    --space-xxxs: 0.4rem;
    --space-xxs: 0.8rem;
    --space-xs: 1rem;
    --space-s: 1.2rem;
    --space-m: 1.6rem;
    --space-l: 2.4rem;
    --space-xl: 3.2rem;
    --space-xxl: 4rem;
    --space-xxxl: 6rem;

    --ion-margin: var(--space-l);
    --ion-padding: var(--space-m);

    --ion-safe-area-top: var(--ion-margin);
    --ion-safe-area-bottom: var(--ion-margin);

    ////// BORDERS

    --default-border-radius: 0.4rem;
    --default-border-color: var(--ion-color-light-blue);
    --default-border-width: 1px;

    ////// CARDS

    --ion-card-color: var(--ion-text-color);

    ////// INPUTS

    --default-input-height: 3.7rem;

    --default-placeholder-color: var(--ion-color-dark);
    --default-placeholder-opacity: 0.4;

    --input-disabled-opacity: 0.7;
}

.ion-color-medium-light {
    --ion-color-base: var(--ion-color-medium-light);
    --ion-color-base-rgb: var(--ion-color-medium-light-rgb);
    --ion-color-contrast: var(--ion-color-medium-light-contrast);
    --ion-color-contrast-rgb: var(--ion-color-medium-light-contrast-rgb);
    --ion-color-shade: var(--ion-color-medium-light-shade);
    --ion-color-tint: var(--ion-color-medium-light-tint);
}

.ion-color-light-blue {
    --ion-color-base: var(--ion-color-light-blue);
    --ion-color-base-rgb: var(--ion-color-light-blue-rgb);
    --ion-color-contrast: var(--ion-color-light-blue-contrast);
    --ion-color-contrast-rgb: var(--ion-color-light-blue-contrast-rgb);
    --ion-color-shade: var(--ion-color-light-blue-shade);
    --ion-color-tint: var(--ion-color-light-blue-tint);
}

// @media (prefers-color-scheme: dark) {
//     /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//     body {
//         --ion-color-primary: #428cff;
//         --ion-color-primary-rgb: 66, 140, 255;
//         --ion-color-primary-contrast: #ffffff;
//         --ion-color-primary-contrast-rgb: 255, 255, 255;
//         --ion-color-primary-shade: #3a7be0;
//         --ion-color-primary-tint: #5598ff;

//         --ion-color-secondary: #50c8ff;
//         --ion-color-secondary-rgb: 80, 200, 255;
//         --ion-color-secondary-contrast: #ffffff;
//         --ion-color-secondary-contrast-rgb: 255, 255, 255;
//         --ion-color-secondary-shade: #46b0e0;
//         --ion-color-secondary-tint: #62ceff;

//         --ion-color-tertiary: #6a64ff;
//         --ion-color-tertiary-rgb: 106, 100, 255;
//         --ion-color-tertiary-contrast: #ffffff;
//         --ion-color-tertiary-contrast-rgb: 255, 255, 255;
//         --ion-color-tertiary-shade: #5d58e0;
//         --ion-color-tertiary-tint: #7974ff;

//         --ion-color-success: #2fdf75;
//         --ion-color-success-rgb: 47, 223, 117;
//         --ion-color-success-contrast: #000000;
//         --ion-color-success-contrast-rgb: 0, 0, 0;
//         --ion-color-success-shade: #29c467;
//         --ion-color-success-tint: #44e283;

//         --ion-color-warning: #ffd534;
//         --ion-color-warning-rgb: 255, 213, 52;
//         --ion-color-warning-contrast: #000000;
//         --ion-color-warning-contrast-rgb: 0, 0, 0;
//         --ion-color-warning-shade: #e0bb2e;
//         --ion-color-warning-tint: #ffd948;

//         --ion-color-danger: #ff4961;
//         --ion-color-danger-rgb: 255, 73, 97;
//         --ion-color-danger-contrast: #ffffff;
//         --ion-color-danger-contrast-rgb: 255, 255, 255;
//         --ion-color-danger-shade: #e04055;
//         --ion-color-danger-tint: #ff5b71;

//         --ion-color-dark: #f4f5f8;
//         --ion-color-dark-rgb: 244, 245, 248;
//         --ion-color-dark-contrast: #000000;
//         --ion-color-dark-contrast-rgb: 0, 0, 0;
//         --ion-color-dark-shade: #d7d8da;
//         --ion-color-dark-tint: #f5f6f9;

//         --ion-color-medium: #989aa2;
//         --ion-color-medium-rgb: 152, 154, 162;
//         --ion-color-medium-contrast: #000000;
//         --ion-color-medium-contrast-rgb: 0, 0, 0;
//         --ion-color-medium-shade: #86888f;
//         --ion-color-medium-tint: #a2a4ab;

//         --ion-color-light: #222428;
//         --ion-color-light-rgb: 34, 36, 40;
//         --ion-color-light-contrast: #ffffff;
//         --ion-color-light-contrast-rgb: 255, 255, 255;
//         --ion-color-light-shade: #1e2023;
//         --ion-color-light-tint: #383a3e;
//     }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: #000000;
//     --ion-background-color-rgb: 0,0,0;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     --ion-item-background: #000000;

//     --ion-card-background: #1c1c1d;
//   }

//   .ios ion-modal {
//     --ion-background-color: var(--ion-color-step-100);
//     --ion-toolbar-background: var(--ion-color-step-150);
//     --ion-toolbar-border-color: var(--ion-color-step-250);
//   }

//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-background-color: #121212;
//     --ion-background-color-rgb: 18,18,18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-border-color: #222222;

//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;

//     --ion-item-background: #1e1e1e;

//     --ion-toolbar-background: #1f1f1f;

//     --ion-tab-bar-background: #1f1f1f;

//     --ion-card-background: #1e1e1e;
//   }
// }
