i[class*='ph-'] {
    &:before {
        font-size: 1.8rem;
    }

    &[slot='start'] {
        margin-right: var(--space-xs);
    }

    &[slot='end'] {
        margin-left: var(--space-xs);
    }
}

app-popover {
    div > {
        i[class*='ph-'] {
            &:before {
                font-size: 5rem;
            }
        }
    }
}

// Concerne les pictos dans les titres h3 des rubriques :
h3 i[class*='ph-'] {
    vertical-align: -2px; // Recentrage par rapport au texte
    width: var(--space-l); // Pour écarter le picto du texte

    &:before {
        font-size: var(--font-size-l);
    }
}
