.form-actions {
    display: flex;
    justify-content: space-between;

    &-reverse {
        @extend .form-actions;
        flex-direction: row-reverse;
    }
}

.form-mandatory {
    font-size: var(--font-size-xs);
    font-style: italic;
    margin-bottom: var(--space-m);

    &::before {
        content: '* ';
        color: var(--ion-color-danger);
        font-weight: 600;
        font-style: normal;
    }
}

.form-item {
    --background: transparent;
    --ion-safe-area-right: 0;
    --padding-start: 0;
    --padding-end: 0;
    margin-bottom: var(--ion-margin);

    .label-stacked {
        font-weight: 600;
        font-size: var(--font-size-m);
        color: var(--ion-color-dark);
        transform: initial;
        margin-bottom: var(--space-xxs);
    }

    &.checkbox-wrapper {
        --ripple-color: transparent;
        --background-hover: transparent;
        display: flex;
        align-items: flex-start;

        ion-label {
            margin: 0;
        }

        .sc-ion-label-md-h {
            white-space: normal;
            font-weight: 600;
            font-size: var(--font-size-m);
            color: var(--ion-color-dark);

            &::after {
                content: none;
            }
        }

        .checkbox-sublabel {
            font-size: var(--font-size-s);
            font-weight: 400;
            display: block;
        }

        ion-checkbox[slot='start'] {
            margin: 0.15rem var(--space-xs) 0 0;
        }
    }

    &.toggle-wrapper {
        margin-bottom: 0;
        div {
            display: flex;
            flex-direction: row;
            align-items: center;

            ion-toggle {
                padding-left: 0;
            }

            span {
                color: var(--ion-color-dark);
            }
        }
    }
}

.form-item-radio {
    --background: transparent;
    --ion-safe-area-right: 0;
    --padding-start: 0;
    --padding-end: 0;

    ion-label.sc-ion-label-md-h {
        --color: var(--ion-color-dark);
        font-weight: 400;
    }

    &.item-radio-checked {
        ion-label {
            --color: var(--ion-color-primary);
            font-weight: 600;
        }
    }
}

ion-label:not([required]) {
    &::after {
        content: '(facultatif)';
        font-size: var(--font-size-s);
        font-weight: 400;
    }
}

ion-radio-group {
    ion-item {
        --padding-start: 0;
        --inner-padding-start: var(--ion-padding);
    }

    ion-label {
        &.sc-ion-select-popover.sc-ion-label-md-h {
            --color: var(--ion-color-dark);
        }

        &:not([required]) {
            &::after {
                content: initial;
            }
        }
    }
}

.description {
    white-space: break-spaces;

    span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }
}

.input-button {
    display: flex;
    width: 100%;
    height: var(--default-input-height);

    ion-input {
        border-radius: var(--default-border-radius) 0 0 var(--default-border-radius);
        border-right-width: 0;
    }

    ion-button {
        height: 100%;
        --border-radius: 0 var(--default-border-radius) var(--default-border-radius) 0;
    }
}

// Rend les textes des formulaires "readonly" sélectionnables :
// contact-form, profil-form...
ion-item {
    ion-text {
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }
}
