@font-face {
    font-family: 'Raleway';
    src: url('../assets/font/Raleway-VariableFont_wght.ttf') format('truetype-variations');
    font-style: normal;
    font-weight: 400 800;
}

@font-face {
    font-family: 'Raleway';
    src: url('../assets/font/Raleway-Italic-VariableFont_wght.ttf') format('truetype-variations');
    font-style: italic;
    font-weight: 400 800;
}
