/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import 'theme/fonts.scss';
@import 'theme/typography.scss';
@import 'theme/ionic.scss';
@import 'theme/swiper.scss';
@import 'theme/phosphor-icons.scss';
@import 'theme/forms.scss';
@import 'theme/loader.scss';

/* SWIPER */
@import '~swiper/scss';
@import '~swiper/scss/pagination';
@import '~@ionic/angular/css/ionic-swiper';

// https://www.aleksandrhovhannisyan.com/blog/respecting-font-size-preferences-rems-62-5-percent/
html {
    font-size: 62.5%; // 1rem = 10px
}

body {
    font-size: var(--default-font-size, 1.6rem); // Si première valeur non définie, fallback sur 1.6em (16px).
}

@media (pointer: fine) {
    ::-webkit-scrollbar {
        height: 5px;
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        background: var(--ion-color-secondary);
    }

    ::-webkit-scrollbar-track:hover {
        background: var(--ion-color-tertiary);
    }

    ::-webkit-scrollbar-thumb {
        background-color: var(--ion-color-primary);
        border-radius: 6px;
        border: 3px solid var(--ion-color-secondary);
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: var(--ion-color-tertiary);
        border-radius: 6px;
        border: 3px solid var(--ion-color-secondary);
    }

    * {
        scrollbar-width: thin;
        scrollbar-color: var(--ion-color-primary);
    }
}
