.app-loading {
    .logo {
        width: 64px;
        height: 64px;

        // this way asset gets processed by webpack
        background: url('../assets/icon/favicon.png') center center no-repeat;
        background-size: contain;
    }
}
