:root {
    --bullet-background: var(--ion-color-light-blue);
    --bullet-background-active: var(--ion-color-primary);
    --swiper-pagination-bullet-inactive-opacity: 1;
}

.swiper {
    width: 100%;
    height: 100%;

    .swiper-slide {
        background-color: var(--ion-color-secondary);
        flex-direction: column;
        justify-content: stretch;
        padding: var(--ion-margin);

        > div {
            flex: 1;
            display: flex;
            align-items: center;
        }

        .image-container {
            justify-content: center;
            margin-bottom: var(--space-xxl);
        }

        .text-container {
            flex-direction: column;
        }
    }

    .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 0;
    }
}
