h1 {
    font-size: var(--font-size-xl);
    font-weight: 800;
}

h2 {
    font-size: var(--font-size-xl);
    font-weight: 600;
    margin-top: 0;
    margin-bottom: var(--space-m);

    display: flex;
    align-items: center;

    app-round-picto {
        display: inline-block;
        margin-right: var(--space-m);
    }
}

h3 {
    font-size: var(--font-size-l);
    font-weight: 700;
}

b,
strong {
    font-weight: 600;
}

.small {
    font-size: var(--font-size-s);
}

.small-bold {
    @extend .small;
    font-weight: bold;
}

.small-uppercase {
    @extend .small-bold;
    text-transform: uppercase;
}

* {
    line-height: 2.4rem;
}
